/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

var PRC = {};
PRC.dropDown = function(){
  var target = jQuery(this).attr('data-target');

  jQuery('.' + target).slideToggle();
};

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        jQuery('.drop-activate').click(PRC.dropDown);
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
          $('[data-toggle="tooltip"]').tooltip(); 

        jQuery('.donate-button').click(function(){
          swal( "Donate",
                "Thank you for your interest in supporting this project! We haven't set up a donate form yet, but you can contact us at <a href='mailto:patrick@prcapps.com'>patrick@prcapps.com</a> in the meantime!");
        });

        console.log('test123');
        jQuery(".acf-form").find('textarea').attr('placeholder', 'Message:');
        jQuery(".acf-form").find('#acf-_post_title').attr('placeholder', 'Subject:');
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    'community_playback' : {
      finalize: function() {
         jQuery(document).ready(function() {
            jQuery(".light-slider").lightSlider({
              item: 5,
              autoWidth: false,
              slideMove: 2, // slidemove will be 1 if loop is true
              slideMargin: 10,
       
              addClass: '',
              mode: "slide",
              useCSS: true,
              cssEasing: 'cubic-bezier(0.25, 0, 0.25, 1)',//
              // easing: 'linear', //'for jquery animation',////
             easing: 'cubic-bezier(0.25, 0, 0.25, 1)',


              speed: 400, //ms'
              auto: false,
              loop: false,
              // slideEndAnimation: true,
              // pause: 2000,
       
              keyPress: false,
              controls: true,
              prevHtml: "<span style='font-size:3.5rem; color: black; font-weight: bold'><</span>",
              nextHtml: "<span style='font-size:3.5rem; color: black; font-weight: bold'>></span>",
       
              rtl:false,
              adaptiveHeight:false,
              
              pager: true,
              currentPagerPosition: 'middle',
       
              enableTouch:true,
              enableDrag:true,
              freeMove:true,
              swipeThreshold: 40,
       
              responsive : [{
                breakpoint:800,
                settings: {
                    item:4,
                    slideMove:2,
                  }
            },
            {
                breakpoint:600,
                settings: {
                    item:3,
                    slideMove:1
                  }
            }],
       
            });
        });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
